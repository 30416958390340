<template>
  <page-view>
    <div class="close_wrap">
      <div class="btn_header">
        <a-textarea
          v-model:value="waybillNo"
          :rows="4"
          placeholder="多个单号请用回车分隔"
          style="width: 300px"
        />
        <a-button :loading="loading" type="primary" @click="getList"
          >搜索</a-button
        >
        <a-button type="primary" @click="exportData">导出数据</a-button>
      </div>
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :loading="loading"
        :pagination="false"
      >
      </a-table>
    </div>
  </page-view>
</template>
<script>
import { PageView } from "@/layouts";
import { queryComplaintList, queryStoreList } from "@/api";
import { exportXLSX } from "@/utils/excel/export";
export default {
  components: {
    PageView
  },
  data() {
    return {
      waybillNo: "",
      columns: [
        {
          title: "快递单号",
          dataIndex: "waybillNo",
          align: "center"
        },
        {
          title: "用户ID",
          dataIndex: "uid",
          align: "center"
        },
        {
          title: "用户手机号",
          dataIndex: "mobile",
          align: "center",
          customRender: (_, record) => record.account.mobile
        },
        {
          title: "仓库名称",
          dataIndex: "storeName",
          align: "center"
        }
      ],
      dataSource: [],
      loading: false,
      storeHex: {}
    };
  },
  async beforeCreate() {
    const res = await queryStoreList({ enabled: true });
    if (res.code === 0) {
      this.storeHex = res.data.reduce((sum, val) => {
        sum[val.uid] = val.name;
        return sum;
      }, {});
    }
  },
  methods: {
    async getList() {
      const waybillNoList = this.waybillNo
        .split("\n")
        .map(val => val?.trim() || "")
        .filter(val => val);
      this.loading = true;
      const res = await queryComplaintList(waybillNoList);
      res.data.forEach(val => {
        val.storeName = this.storeHex[val.sellUid];
      });
      this.loading = false;
      this.dataSource = res.data;
    },
    exportData() {
      const result = [["快递单号", "用户ID", "用户手机号", "仓库名称"]];
      let data = this.dataSource.map(val => [
        val.waybillNo,
        val.uid,
        val.account.mobile,
        val.storeName
      ]);
      // 排序手机号
      data = data.sort((a,b) => a[2] - b[2])
      result.push(...data);
      if (result.length <= 1) return this.$message.error("无数据可导！");
      exportXLSX(result, `投诉数据${new Date().getTime()}`);
    }
  }
};
</script>
<style lang="less" scoped>
.close_wrap {
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  .btn_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }
}
</style>
